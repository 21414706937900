import React, { useState } from 'react';
import './usercomments.css';

const UserComments = () => {
  const [comments, setComments] = useState([]);
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleRatingClick = (index) => {
    setRating(index + 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name && comment) {
      const newComment = {
        id: comments.length + 1,
        name: name,
        comment: comment,
        rating: rating,
        date: new Date().toLocaleString(),
      };
      setComments([...comments, newComment]);
      setName('');
      setComment('');
      setRating(0); // Скинути рейтинг після надсилання
    }
  };

  return (
    <div className="comments-section">
      <h2 className='LeaveComment'>Залишити коментар</h2>
      <form onSubmit={handleSubmit} className="comment-form">
        <input
          type="text"
          placeholder="Ваше ім'я"
          value={name}
          onChange={handleNameChange}
          required
        />
        <textarea
          placeholder="Ваш коментар"
          value={comment}
          onChange={handleCommentChange}
          required
        ></textarea>
        <div className="rating-stars">
          {[0, 1, 2, 3, 4].map((index) => (
            <span
              key={index}
              className={`star ${index < rating ? 'filled' : ''}`}
              onClick={() => handleRatingClick(index)}
            >
              ★
            </span>
          ))}
        </div>
        <button type="submit">Надіслати</button>
      </form>

      <div className="comments-list">
        {comments.map((item) => (
          <div key={item.id} className="comment-item">
            <p className="comment-name">{item.name}:</p>
            <p className="comment-text">{item.comment}</p>
            <p className="comment-date">{item.date}</p>
            <div className="comment-rating">
              {Array.from({ length: 5 }, (_, index) => (
                <span
                  key={index}
                  className={`star ${index < item.rating ? 'filled' : ''}`}
                >
                  ★
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserComments;

import React from "react";
import "./section.css";

const Section6 = () => (
  <section id="section6">
    <div className="block_galeri_page_5">
      <span style={{ "--i": 0 }}>
        <img src="/img_galeri/foto_galeri1.svg" alt="" />
      </span>
      <span style={{ "--i": 1 }}>
        <img src="/img_galeri/foto_galeri2.svg" alt="" />
      </span>
      <span style={{ "--i": 2 }}>
        <img src="/img_galeri/foto_galeri3.svg" alt="" />
      </span>
      <span style={{ "--i": 3 }}>
        <img src="/img_galeri/foto_galeri4.svg" alt="" />
      </span>
      <span style={{ "--i": 4 }}>
        <img src="/img_galeri/foto_galeri5.svg" alt="" />
      </span>
      <span style={{ "--i": 5 }}>
        <img src="/img_galeri/foto_galeri6.svg" alt="" />
      </span>
      <span style={{ "--i": 6 }}>
        <img src="/img_galeri/foto_galeri7.png" alt="" />
      </span>
      <span style={{ "--i": 7 }}>
        <img src="/img_galeri/foto_galeri8.png" alt="" />
      </span>
      <span style={{ "--i": 8 }}>
        <img src="/img_galeri/foto_galeri9.png" alt="" />
      </span>
    </div>
  </section>
);

export default Section6;

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './components/header/header.css';
import './components/sections/section.css';
import './components/footer/footer.css';

import App from './App';
import reportWebVitals from './reportWebVitals';


const AppWithWindowSize = () => {
  useEffect(() => {
    // Функція для виведення розміру вікна
    const logWindowSize = () => {
      console.log(`Ширина: ${window.innerWidth}px, Висота: ${window.innerHeight}px`);
    };

    // Виводимо розмір вікна при завантаженні сторінки
    logWindowSize();

    // Додаємо слухач події зміни розміру
    window.addEventListener('resize', logWindowSize);

    // Прибираємо слухач при демонтажі компонента
    return () => {
      window.removeEventListener('resize', logWindowSize);
    };
  }, []);

 
};

// Рендеримо компонент
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppWithWindowSize />    
    <App />
  </React.StrictMode>

);

reportWebVitals();
export default App
import React from 'react';
import './section.css';

const Section8 = () => (
  <>
 
  <section id="section8">

      
      <div className="block_page_8_rozmirna_sitka">
        <img src="/img/RozmirnaSitkaIMG.svg" width={"100%"} alt=""></img>
      </div>
     
  </section>
  </>
);

export default Section8;

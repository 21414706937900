import React, { useState } from 'react';
import './css/TermsOfUse.css';

const TermsOfUse = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null; // Якщо isVisible - false, не рендеримо компонент

  return (
    <div className="container">
      <button onClick={handleClose} className="close-button">Закрити</button>
      <h1>Умови використання</h1>
      <p>Ці Умови використання регулюють ваш доступ до нашого сайту та користування ним. Прочитайте ці Умови уважно, перш ніж використовувати сайт.</p>

      <h2>1. Загальні положення</h2>
      <p>Використовуючи наш сайт, ви погоджуєтесь дотримуватись цих Умов. Якщо ви не погоджуєтесь із будь-яким із положень, не використовуйте наш сайт.</p>

      <h2>2. Внесення змін до Умов</h2>
      <p>Ми можемо періодично змінювати ці Умови. Ви погоджуєтесь переглядати їх регулярно, щоб бути в курсі можливих змін.</p>

      <h2>3. Авторські права та права інтелектуальної власності</h2>
      <p>Весь вміст сайту, включаючи текст, зображення, логотипи та інші матеріали, є нашою власністю або власністю наших партнерів та захищений авторськими правами. Використання цих матеріалів без нашої письмової згоди забороняється.</p>

      <h2>4. Використання сайту</h2>
      <p>Ви зобов'язуєтесь використовувати сайт лише у законних цілях і не порушувати права третіх осіб, не завдавати шкоди нашому сайту чи його користувачам.</p>

      <h2>5. Відповідальність</h2>
      <p>Ми не несемо відповідальності за будь-які збитки, що виникли внаслідок використання сайту. Ми також не гарантуємо безперебійної роботи сайту або його безпеки.</p>

      <h2>6. Посилання на сторонні ресурси</h2>
      <p>Наш сайт може містити посилання на сторонні сайти. Ми не контролюємо ці ресурси і не несемо відповідальності за їх вміст чи політику конфіденційності.</p>

      <h2>7. Зв'язок із нами</h2>
      <p>Якщо у вас є питання щодо цих Умов використання, будь ласка, зв'яжіться з нами за електронною адресою <a href="mailto:senseykaira@gmail.com">senseykaira@gmail.com</a>.</p>

      <p>Дата останнього оновлення: 23 вересня 2024 року</p>
    </div>
  );
};

export default TermsOfUse;

import React from 'react';
import './section.css';

const Section5 = () => (
  <>
 
  <section id="section5" className="section5">
    <div className="product-container">
      <div className="product-card">
        <img src="/img/card_shirt.png" alt="Товар 1" className="product-image" />
        <h3>Костюм + барсетка</h3>
        <p>Ціна: 1999 грн</p>
        <a href="#SectionForm"><button className="order-button">Замовити</button></a>
      </div>
      <div className="product-card">
        <img src="/img/card_shirt2.png" alt="Товар 2" className="product-image" />
        <h3>Курточка</h3>
        <p>Ціна: 1299 грн</p>
        <a href="#SectionForm"><button className="order-button">Замовити</button></a>
      </div>
      <div className="product-card">
        <img src="/img/card_shirt3.png" alt="Товар 3" className="product-image" />
        <h3>Штани</h3>
        <p>Ціна: 999 грн</p>
        <a href="#SectionForm"><button className="order-button">Замовити</button></a>
      </div>
    </div>
  </section>
  </>
);

export default Section5;

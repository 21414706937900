import React from "react";
import "./section.css";

const Section2 = () => (
  <>
   
    <section id="section2" className="section2">
      <div className="grid-container">
        <h1 className="title">Чому варто обрати цей спортивний костюм?</h1>
        <div className="text">
          <h2>Комфорт</h2>
          <p>
            Зручний крій, який не обмежує рухів, робить цей костюм ідеальним для
            тренувань та повсякденного носіння.
          </p>
          <h2>Стиль</h2>
          <p>Модний дизайн, який підходить до будь-якого образу.</p>
          <h2>Якість</h2>
          <p>
            Матеріал зберігає свій вигляд навіть після тривалого використання.
          </p>
        </div>
        <div className="image">
          <img src="/img/imgPage_2.png" alt="Спортивний костюм" />
        </div>
      </div>
    </section>
  </>
);

export default Section2;

import React, { useEffect } from 'react';
import './header.css';

const Header = () => {
  useEffect(() => {
    const menuToggle = document.getElementById('menu-toggle');
    const menu = document.getElementById('menu');
    const sections = document.querySelectorAll("section");
    const navLinks = document.querySelectorAll(".menu a");
    const header = document.querySelector('header');

    // Логування для перевірки наявності елементів
    console.log('Sections:', sections);
    console.log('NavLinks:', navLinks);

    const changeActiveLink = () => {
      if (sections.length > 0 && navLinks.length > 0) {
        sections.forEach((section, i) => {
          if (window.scrollY >= section.offsetTop - 50) {
            navLinks.forEach((link) => link.classList.remove("active"));
            if (navLinks[i]) {
              navLinks[i].classList.add("active");
            }
          }
        });
      }
    };

    navLinks.forEach(link => {
      link.addEventListener('click', () => {
        if (menu && menuToggle) {
          menu.classList.remove('active');
          menuToggle.classList.remove('active');
        }
      });
    });

    const handleMenuToggle = () => {
      if (menu && menuToggle) {
        menu.classList.toggle('active');
        menuToggle.classList.toggle('active');
      }
    };

    const handleScroll = () => {
      if (header) {
        if (window.scrollY > 100) {
          header.classList.add('light');
        } else {
          header.classList.remove('light');
        }
      }

      sections.forEach((section) => {
        const sectionTop = section.offsetTop - 50;
        const sectionHeight = section.clientHeight;
        const sectionBottom = sectionTop + sectionHeight;

        if (window.scrollY >= sectionTop && window.scrollY <= sectionBottom) {
          if (getComputedStyle(section).backgroundColor === 'rgb(255, 255, 255)') {
            navLinks.forEach(link => {
              link.classList.add('light-background');
              link.classList.remove('dark-background');
            });
          } else {
            navLinks.forEach(link => {
              link.classList.add('dark-background');
              link.classList.remove('light-background');
            });
          }
        }
      });
    };

    // Перевірка наявності menuToggle перед додаванням обробника
    if (menuToggle) {
      menuToggle.addEventListener('click', handleMenuToggle);
    }

    window.addEventListener("scroll", changeActiveLink);
    window.addEventListener('scroll', handleScroll);

    return () => {
      if (menuToggle) {
        menuToggle.removeEventListener('click', handleMenuToggle);
      }
      window.removeEventListener("scroll", changeActiveLink);
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);

  return (
    <header className="header" id='header'>     
      <nav>
      <span className='logoHeaderMil'>Mil</span>
      <span className='logoHeaderArt'>Art</span>
        <div className="menu-toggle" id="menu-toggle">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        
        <ul className="menu" id="menu">
     
          <li><a id="Golovna" href="#section1">Головна</a></li>
          <li><a href="#section2">Переваги</a></li>
          <li><a href="#section4">Пропозиція</a></li>
          <li><a href="#section5">Товар</a></li>
          <li><a href="#section6">Галерея</a></li>
          <li><a href="#section7">Відгуки</a></li>
          <li><a href="#section8">Розмір</a></li>
          <li><a href="#SectionForm">Контакти</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;

import React from "react";
import Header from "./components/header/Header";
import Headroom from "react-headroom";
import Section1 from "./components/sections/Section1";
import Section2 from "./components/sections/Section2";
import Section3 from "./components/sections/Section3";
import Section4 from "./components/sections/Section4";
import Section5 from "./components/sections/Section5";
import Section6 from "./components/sections/Section6";
import Section7 from "./components/sections/Section7";
import Section8 from "./components/sections/Section8";
import SectionForm from "./components/sections/SectionForm";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from "./components/footer/Footer"; 
import TermsOfUse from './components/sections/TermsOfUse';
import PrivacyPolicy from './components/sections/PrivacyPolicy';
import { Helmet } from "react-helmet";

function App() {
  return (
    <Router>
      <div>
        <Headroom>
          <Header />
        </Headroom>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <SectionForm />
        <Routes>
        <Route path="/SectionForm" element={<SectionForm />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import './section.css';
import UserComments from '../usercomments/UserComments'; // Перевірте правильність шляху

const Section7 = () => (
  <>
 
  <section id="section7">
    <h1 id="vidguk_page_7_h1">Відгуки</h1>
    <div className="block_page_7_main">
      <div className="block_vidhuku">
        <div className="spanKartochka_1">
          <img src="/img/zelenskij.jpeg" alt="Володимир Зеленський" className="vidguk-image" />
          <h3>Володимир Зеленський</h3>
          <p>Вдяг бандерофутболку ЗДАЮТЬСЯ ТІЛЬКИ ОРКИ. Тепер не можу відбитися від незнайомих людей посеред вулиці, бо усі хочуть фотку з тим гусаком. Щиро вам дякую, блін!</p>
        </div>
        <div className="spanKartochka_2">
          <img src="/img/bezus.webp" alt="Jeff Bezos" className="vidguk-image" />
          <h3>Jeff Bezos</h3>
          <p>Купив нещодавно ваше худі та вже провів у ньому ефір на своєму ютуб-каналі. Це щось неймовірне: лайків стало втричі більше! А у коментах засипали компліментами, що заздрить вся русня...</p>
        </div>
        <div className="spanKartochka_3">
          <img src="/img/djenifer.jpeg" alt="Jennifer Lopez" className="vidguk-image" />
          <h3>Jennifer Lopez</h3>
          <p>I ordered a cover with READY TO TEAR ASSHOLE. Now on the set, when the producer comes up with another piece of advice, I raise the phone and say "Accepted, BOSS!" P.S. The salary has not yet been raised...</p>
        </div>
      </div>
    </div>
    <UserComments /> {/* Додаємо компонент UserComments знизу */}
  </section>
  </>
);

export default Section7;

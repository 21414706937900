import React from 'react';
import './section.css';

const Section3 = () => (
  <>
    
  <section id="section3">
    <div className="blockpoage_3">
      <div className="block_1_page_3">
        <div className="block_img_1">
          <img src="/img/block_1.svg" alt="Якість,Швидкість,Оплата" />
        </div>
        <p className="text_h2_block_1">Якість</p>
        <div className="text_block_1_span">
          <p>Наші професіонали працюють на найкращому обладнанні для пошиття одягу безпрецедентної якості</p>
        </div>
      </div>
      <div className="block_2_page_3">
        <div className="block_img_2">
          <img src="/img/Vector.svg" alt="Якість,Швидкість,Оплата" />
        </div>
        <p className="text_h2_block_2">Швидкість</p>
        <div className="text_block_2_span">
          <p>Завдяки налагодженій системі в Womazing ми можемо відшивати до 20-ти одиниць продукції в наших власних цехах</p>
        </div>
      </div>
      <div className="block_3_page_3">
        <div className="block_img_2">
          <img src="/img/block_2.svg" alt="Якість,Швидкість,Оплата" />
        </div>
        <p className="text_h2_block_2">Оплата</p>
        <div className="text_block_2_span">
          <p>Ми дбаємо про людей та планету. Безвідходне виробництво і комфортні умови праці — це Womazing</p>
        </div>
      </div>
    </div>
  </section>
  </>
);

export default Section3;

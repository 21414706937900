import React from 'react';
import { Link } from 'react-router-dom'; // Імпорт компоненту Link
import './footer.css'; // Переконайтесь, що цей шлях правильний

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-left">
          <h4 className="footerContactH4">Контакти</h4>
          <p>Телефон: +380 2903071</p>
          <p>Email: <a href="mailto:senseykaira@gmail.com">senseykaira@gmail.com</a></p>
          <p>Адреса: м. Мукачево</p>
        </div>
        <div className="footer-center">
          <h2 className="textKontact">Контакти</h2>
        </div>
        <div className="footer-right">
          <div className="social-icons">
            <h3 className="h4footer">Наші соцмережі</h3>
            <a href="https://www.facebook.com/profile.php?id=61556663582264" aria-label="Перейти на Facebook">
              <img src="/img/facebook.png" alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/milart_insta_shop?igsh=MWVxNXdzeTRoMWtiZQ==" aria-label="Перейти на Instagram">
              <img src="/img/instagram.png" alt="Instagram" />
            </a>
            <a href="https://t.me/MilArt_shop" target="_blank" rel="noopener noreferrer" aria-label="Перейти на Telegram">
              <img src="/img/telegram.svg" alt="Telegram" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-links">
        {/* Використовуйте Link замість звичайних тегів <a> */}
        <Link className="footer-links_a" to="/privacy-policy">Політика конфіденційності</Link>
        <Link className="footer-links_a" to="/terms-of-use">Умови використання</Link>
      </div>
      <div className="footer-signature">
        <p>&copy; 2024 MilArt. Всі права захищені.</p>
      </div>
    </footer>
  );
};

export default Footer;
